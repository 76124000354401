import React, { FC } from 'react'
import { utils } from 'utils'

const TestPage: FC = () => {
 return (
  <div>
   {utils.formatPhoneNumber('919562191932')}
   Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quae
   perferendis et assumenda corporis corrupti sint, voluptate adipisci!
   Recusandae commodi officiis magni dignissimos saepe consectetur rerum
   necessitatibus. Doloremdddddddddddddddddddddddddddddddddddddddddque
   temporibus minima aliquam, consequuntur nam ex
  </div>
 )
}

export default TestPage

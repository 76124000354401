import { flexRender, Row } from '@tanstack/react-table'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import { FC } from 'react'
import MTCell from '../Cell/MTCell'

const MTBodyRow: FC<{
 row: Row<any>
 onRowClick?: () => void
}> = ({ row, onRowClick }) => {
 return (
  <tr
   onClick={onRowClick}
   className={classNames('m-table-row', {
    'm-table-row-is-selected': row.getIsSelected(),
   })}
  >
   {row.getVisibleCells().map((cell: any, index) => {
    return (
     <MTCell key={index} column={cell.column}>
      {cell.column.columnDef.meta?.ellipsis ? (
       <Tooltip
        title={flexRender(cell.column.columnDef.cell, cell.getContext())}
       >
        <span className="overflow-hidden d-block">
         {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </span>
       </Tooltip>
      ) : (
       <span className="m-table-cell-span">
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
       </span>
      )}
     </MTCell>
    )
   })}
  </tr>
 )
}

export default MTBodyRow

import { useAppSelector, useAppDispatch } from 'hooks'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TGlobalTypes } from 'types'
import { baseAPI } from 'api/baseApi'
import { RootState } from 'store'

export type TGlobalState = TGlobalTypes & {
 notification: boolean
 unread_chat_count: number
 propertyModalState: {
  show: boolean
  slug: string
 }
 searchFieldState: {
  field: string
 }
 neighbourhood?: string[]
}

const globalState: TGlobalState = {
 body_mergefield: [],
 subject_mergefield: [],
 notification: false,
 unread_chat_count: 0,
 unreadnoti: 0,
 propertyModalState: {
  show: false,
  slug: '',
 },
 searchFieldState: {
  field: '',
 },
 brokerage_data: [],
}

export const globalSlice = createSlice({
 name: 'globalSlice',
 initialState: globalState,
 reducers: {
  toggleNotification: (state) => {
   state.notification = !state.notification
  },
  updateLeadAssignType: (state, payload) => {
   state.lead_assign_type = payload?.payload
  },

  //   lead_assign_type
  updateNotificationCount: (state) => {
   if (state.unreadnoti) {
    state.unreadnoti -= 1
   }
  },
  UpdatePropModalState: (
   state,
   {
    payload,
   }: PayloadAction<{
    show?: boolean
    slug?: string
   }>,
  ) => {
   state.propertyModalState.show = !state.propertyModalState.show
   state.propertyModalState.slug = payload.slug ?? ''
  },
  setFieldToSearch: (
   state,
   {
    payload,
   }: PayloadAction<{
    field?: string
   }>,
  ) => {
   state.searchFieldState.field = payload.field ?? ''
  },
  updateBrokerageData: (state, payload) => {
   const { type, data } = payload.payload
   const brokerageData: any = state.brokerage_data
   if (type === 'add') {
    state.brokerage_data = [
     ...(new Set((brokerageData ?? [])?.concat?.(data)) as any),
    ]
   } else if (type === 'remove') {
    const newArray = brokerageData.filter((item: string) => item !== data)
    state.brokerage_data = newArray
   }
  },
 },
 extraReducers(builder) {
  builder.addMatcher(
   baseAPI.endpoints.globalSettings.matchFulfilled,
   (state, { payload }) => ({
    ...state,
    ...payload,
   }),
  )
 },
})

export const {
 toggleNotification,
 updateNotificationCount,
 UpdatePropModalState,
 setFieldToSearch,
 updateBrokerageData,
 updateLeadAssignType,
} = globalSlice.actions

export const useGlobal = (): {
 global: TGlobalState
 toggleNotification: () => void
 updateNotificationCount?: () => void
 UpdatePropModalState?: (v: { slug?: string; show?: boolean }) => void
 setFieldToSearch?: (v: { field?: string }) => void
 updateBrokerageData?: (v: { type: 'add' | 'remove'; data: any }) => void
 updateLeadAssignType?: (v: string) => void
} => {
 const d = useAppDispatch()
 const global = useAppSelector((state: RootState) => state.global)
 return {
  global,
  toggleNotification: () => d(toggleNotification()),
  updateNotificationCount: () => d(updateNotificationCount()),
  UpdatePropModalState: (v) => d(UpdatePropModalState(v)),
  setFieldToSearch: (v) => d(setFieldToSearch(v)),
  updateBrokerageData: (v) => d(updateBrokerageData(v)),
  updateLeadAssignType: (v) => d(updateLeadAssignType(v)),
 }
}

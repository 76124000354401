import { Column } from '@tanstack/react-table'
import classNames from 'classnames'
import { HTMLAttributes, useMemo } from 'react'
import { FCC } from 'types'
import { useMTTable } from '../MTContext'
import { CustomComponent } from '../type'
import { stickyColumn } from '../utils/functions'

const MTCell: FCC<{
 type?: 'td' | 'th'
 scrollBarWidth?: number
 colSpan?: any
 column: any
 className?: string
 title?: any
 as?: CustomComponent<HTMLAttributes<any>>
}> = ({
 as: Component = 'td',
 type = 'th',
 children,
 column,
 colSpan,
 className = '',
 scrollBarWidth = 0,
 title,
}) => {
 const { table } = useMTTable()

 const lastColumn = useMemo(
  () =>
   table ? table.getAllColumns()[table.getAllColumns().length - 1] : null,
  [table],
 )
 const SecondColumn: any = useMemo(
  () => (table ? table.getAllColumns()[1] : null),
  [table],
 )
 const firstColumn = useMemo(
  () => (table ? table.getAllColumns()[0] : null),
  [table],
 )

 const wrapperClass = useMemo(
  () =>
   classNames(`m-table-cell ${className}`, {
    'm-table-cell-last': column.id === lastColumn?.id,
    'm-table-selection-column': column.id === 'selection',
    'm-table-cell-fix-left':
     (column.id === 'selection' &&
      SecondColumn?.columnDef.meta?.fixed === 'left') ||
     column.columnDef.meta?.fixed === 'left',
    'm-table-cell-fix-right': column.columnDef.meta?.fixed === 'right',
    'm-table-cell-ellipsis': column.columnDef.meta?.ellipsis,
   }),
  [
   className,
   column.id,
   column.columnDef.meta?.fixed,
   column.columnDef.meta?.ellipsis,
   lastColumn?.id,
   SecondColumn?.columnDef.meta?.fixed,
  ],
 )

 return (
  <Component
   title={title}
   style={{
    ...stickyColumn({
     fixed:
      column.id === 'selection'
       ? SecondColumn?.columnDef.meta?.fixed
       : column.columnDef.meta?.fixed,
     el: type,
     scrollWidth: scrollBarWidth,
     hasSelection:
      firstColumn?.columnDef.id === 'selection' &&
      column.columnDef.meta?.fixed === 'left',
    }),
   }}
   colSpan={colSpan}
   className={wrapperClass}
  >
   {children}
  </Component>
 )
}

export default MTCell

/* eslint-disable no-nested-ternary */
import { NewListingSlug, TGloblListingKeys } from 'types'

type TSetPreLoadContent = {
 single: boolean
 conte?: TGloblListingKeys
 type?: NewListingSlug
 showPreConSingle?: boolean
}
export const setProPreLoadContent = (value: TSetPreLoadContent): string => {
 const { conte, single, type, showPreConSingle = true } = value
 const values: any = {
  sale: single ? conte?.sale_single : conte?.sale_multiple,
  rent: single ? conte?.rent_single : conte?.rent_multiple,
  leased: single ? conte?.leased_single : conte?.leased_multiple,
  sold: single ? conte?.sold_single : conte?.sold_multiple,
  'open-house': single
   ? conte?.['open-house_single']
   : conte?.['open-house_multiple'],
  preconstruction: single
   ? showPreConSingle
     ? conte?.preconstruction_single
     : ''
   : conte?.preconstruction_multiple,
 }
 return values[type ?? ''] ?? ''
}

import useFetchPage from 'hooks/useFetchPage'
import { FC, useMemo } from 'react'
import { TGetLeads } from '../types'
import { useLeadFilter } from '../slice/leadSlice'
import LeadList from '../components/LeadList'
import LeadListTitleTabsBlock from '../components/LeadListTitleTabsBlock'

const MyLeads: FC = () => {
 const { filterParams } = useLeadFilter()

 const {
  flatData,
  isLoading,
  hasMore,
  fetchMore,
  refetch,
  totalCount,
  isRefetching,
 } = useFetchPage<TGetLeads>('get-leads', {
  method: 'post',
  body: useMemo(
   () => ({
    ...filterParams,
    type: 'myleads',
   }),
   [filterParams],
  ),
 })

 return (
  <LeadList
   title={<LeadListTitleTabsBlock selectedKey="my-leads" />}
   fetchMore={fetchMore}
   refetch={refetch}
   from="lead"
   isLoading={isLoading}
   hasMore={hasMore}
   totalCount={totalCount}
   leads={flatData}
   selectedLeadsType="my-leads"
   isRefetching={isRefetching}
  />
 )
}

export default MyLeads

import {
 updateAppControl,
 updateSendWidget,
 resetSendWidget,
} from 'raducer/control'
import { TAppControlState, TSendWidgetState } from 'types'
import { useAppDispatch, useAppSelector } from './index'

type UseAppControlProps = {
 control: TAppControlState
 updateControl: (v: TAppControlState) => void
 sendWidget?: TSendWidgetState
 updateSendSMSWidget: (v: TSendWidgetState) => void
 updateSendEmailWidget: (v: TSendWidgetState) => void
 updateSendWidget: (v: TSendWidgetState) => void
 resetSendWidget?: () => void
 onSuccessEmail?: () => void
 onSuccessSMS?: () => void
}

type TUseAppControl = {
 onSuccessEmail?: () => void
 onSuccessSMS?: () => void
}

const useAppControl = (val?: TUseAppControl): UseAppControlProps => {
 const control = useAppSelector((state) => state.control)
 const d = useAppDispatch()

 const updateSendSMSWidget = (v: TSendWidgetState) => {
  d(
   updateSendWidget({
    widget: 'sms',
    ...v,
   }),
  )
 }
 const updateSendEmailWidget = (v: TSendWidgetState) => {
  d(
   updateSendWidget({
    widget: 'email',
    ...v,
   }),
  )
 }

 //  console.log(val, 'vaalue')
 return {
  updateSendWidget: (V) => d(updateSendWidget(V)),
  updateSendEmailWidget,
  resetSendWidget: () => d(resetSendWidget()),
  updateSendSMSWidget,
  sendWidget: control.sendWidget,
  control,
  updateControl(v) {
   d(updateAppControl(v))
  },
  onSuccessEmail: val?.onSuccessEmail,
  onSuccessSMS: val?.onSuccessSMS,
 }
}

export default useAppControl

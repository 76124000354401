import { FC } from 'react'
import { useMTTable } from './MTContext'

const MTColGroup: FC<{
 colWidths?: any[]
 type?: 'header' | 'body'
}> = ({ type }) => {
 const { table } = useMTTable()

 return (
  <colgroup>
   {table?.getAllColumns().map((header, index) => (
    <col style={{ width: `${header.getSize()}px` }} key={index} />
   ))}
   {type === 'header' && <col style={{ width: '15px' }} />}
  </colgroup>
 )
}

export default MTColGroup

MTColGroup.defaultProps = {
 type: 'body',
}

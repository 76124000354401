import { Col, Form, Input, Alert } from 'antd'
import { MaskedInput } from 'antd-mask-input'
import Modal from 'antd/lib/modal/Modal'
import React, { FC, useEffect, useState } from 'react'
import { useAddLeadMutation } from '../api'
import { TStoreLead } from '../types'

const formInt: TStoreLead = {
 email: '',
 name: '',
 phone: '',
 country_code: '',
}

const LeadForm: FC<{
 visible?: boolean
 onCancel?: () => void
}> = ({ onCancel, visible }) => {
 const [addLead, { isLoading }] = useAddLeadMutation()
 const [cCode, setCCode] = useState('')
 const [form] = Form.useForm<TStoreLead>()
 const emailField = Form.useWatch('email', form)
 const phoneField = Form.useWatch('phone', form)

 const onSubmit = async () => {
  const values = await form.validateFields()
  await addLead({ ...values, country_code: 1 }).unwrap()
  onCancel?.()
 }

 useEffect(
  () => () => {
   form.resetFields()
  },
  [],
 )

 return (
  <Modal
   confirmLoading={isLoading}
   onOk={onSubmit}
   okText="Add Lead"
   title="Add Lead"
   onCancel={onCancel}
   visible={visible}
   okButtonProps={{ disabled: emailField === '' && phoneField === '' }}
  >
   <Form initialValues={formInt} form={form} layout="vertical">
    <Form.Item
     rules={[{ required: true, message: 'Lead name is required' }]}
     name="name"
     label="Full name"
    >
     <Input placeholder="Enter Full name" />
    </Form.Item>
    <Form.Item
     rules={[
      //   { required: true, message: 'Phone is required' },
      { min: 11, message: 'Please enter a valid phone no' },
     ]}
     getValueFromEvent={(e, a) =>
      e.unmaskedValue ? `1${e.unmaskedValue}` : ''
     }
     name="phone"
     label="Phone number"
    >
     <MaskedInput mask="+1(000) 000-0000" />
     {/* <InputPhoneNumber placeholder="" /> */}
    </Form.Item>
    <Form.Item
     name="email"
     label="Email"
     rules={[{ type: 'email', message: 'Please enter a valid email' }]}
    >
     <Input placeholder="Enter Email" type="email" />
    </Form.Item>
   </Form>
   {emailField === '' && phoneField === '' && (
    <Alert
     message="Please provide either email or phone number"
     type="warning"
     showIcon
    />
   )}
  </Modal>
 )
}

export default LeadForm

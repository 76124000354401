import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import { TUserActivityCall } from 'features/leads/types'
import { FC, useState } from 'react'
import useFetchPageNew from 'hooks/useFetchpageNew'
import ActivityLayout from './components/ActivityLayout'

const CallActivity: FC = () => {
 const { leadId, realtorId } = useLeadDetails()
 const [detailData, setDetailData] = useState({})

 const { flatData, isLoading, fetchMore, hasMore, isRefetching, total } =
  useFetchPageNew<TUserActivityCall>(`lead/profile/${leadId}`, {
   body: { type: 'calllog', ...(realtorId ? { realtor_id: realtorId } : {}) },
   method: 'post',
  })
 return (
  <ActivityLayout
   fetchDetail={(val) =>
    setDetailData({
     ccontent: flatData?.[val?.index]?.ccontent,
     cduration: flatData?.[val?.index]?.cduration,
     crecord: flatData?.[val?.index]?.crecord,
    })
   }
   totalCount={total}
   totalTitle="Call Log"
   listFetchMore={fetchMore}
   listHasMore={hasMore}
   listisRefetching={isRefetching}
   flatData={flatData}
   listIsLoading={isLoading}
   detailData={detailData}
   setItemCard={(val: any) => ({
    titleNode: (
     <h3 className="mb-0 mt-0">
      {val?.cfrom_name} <i className="fal fa-angle-right" /> {val?.cto_name}
     </h3>
    ),
    updated: val?.updated,
   })}
  />
 )
}
export default CallActivity

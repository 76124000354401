/* eslint-disable react/require-default-props */
import { flexRender, Header } from '@tanstack/react-table'
import classNames from 'classnames'
import { FC } from 'react'
import MTCell from '../Cell/MTCell'
import { MTFilterProps } from '../type'
import MTFilterPanel from './MTFilterPanel'

const MTHeaderCell: FC<{
 isScroll?: boolean
 header: Header<any, any>
 filter?: MTFilterProps
}> = ({ header, filter, isScroll }) => {
 return (
  <MTCell
   scrollBarWidth={isScroll ? 12 : 0}
   type="th"
   className={classNames({
    'm-table-cell-has-filter': filter && (filter.options || filter?.customNode),
   })}
   as="th"
   column={header.column}
  >
   {header.isPlaceholder ? null : (
    <span className="mt-header-cell-span">
     {flexRender(header.column.columnDef.header, header.getContext())}
     {filter && (filter.options || filter?.customNode) && (
      <MTFilterPanel filter={filter} header={header} />
     )}
    </span>
   )}
   {header.column.getCanResize() && (
    <div
     {...{
      onMouseDown: header.getResizeHandler(),
      onTouchStart: header.getResizeHandler(),
      className: `m-table-resize-col ${
       header.column.getIsResizing() ? 'is-resizing' : ''
      }`,
     }}
    />
   )}
  </MTCell>
 )
}

export default MTHeaderCell

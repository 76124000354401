/* eslint-disable no-nested-ternary */
import { Row, Col, Result, Input, Empty } from 'antd'
import { InfinityPagination } from 'components/elements/BaseInfinityScroll'
import { BlockLoader } from 'components/elements/Loader'
import { ADMIN_PREFIX_PATH } from 'config'
import { TInboxItem } from 'features/inbox/types'
import { FC, useEffect, useState } from 'react'
import { Routes, Route, useParams, NavLink } from 'react-router-dom'
import useFetchPage2 from 'hooks/useFetchPage2'
import InboxItem from 'features/inbox/components/InboxItem'
import InboxScheduleMsg from 'features/inbox/components/InboxScheduleMsg'
import { useDebounce } from 'react-use'
import InboxMessages from './InboxMessages'

const InboxType: FC = () => {
 const params = useParams()
 const type = params?.type
 const mode = params?.mode
 const [searchKey, setSearchKey] = useState('')
 const [apiSearchKey, setApiSearchKey] = useState('')
 const { flatData, hasMore, isLoading, fetchMore, refetch } =
  useFetchPage2<TInboxItem>(`inbox/${mode === 'sms' ? 'text' : mode}`, {
   method: 'get',
   params: `type=${type}&search=${searchKey}`,
   customKey: `inbox-${mode === 'sms' ? 'text' : mode}-${type}-${apiSearchKey}`,
  })

 useEffect(() => {
  setSearchKey('')
  setApiSearchKey('')
 }, [mode, type])

 //  if (isLoading) {
 //   return <BlockLoader mode="full" />
 //  }

 //  if (!flatData || (flatData && flatData.length === 0)) {
 //   return null
 //  }

 useDebounce(
  () => {
   setApiSearchKey(searchKey)
  },
  300,
  [searchKey],
 )

 return (
  <div className="inbox-container">
   <Row gutter={15}>
    <Col className="inbox-sidenav">
     <ul className="left-inbox-links">
      {type !== 'scheduled' && (
       <div className="my-2">
        <Input
         placeholder="Enter Lead Name"
         value={searchKey}
         onChange={({ target }) => setSearchKey(target?.value)}
        />
       </div>
      )}
      {isLoading ? (
       <BlockLoader mode="block" />
      ) : flatData?.length === 0 && !isLoading ? (
       <Empty />
      ) : (
       (flatData?.length ?? 0) > 0 && (
        <InfinityPagination
         hasMore={hasMore}
         loadMore={fetchMore}
         useWindow={false}
        >
         {flatData?.map?.((item, i) => (
          <li key={i}>
           <NavLink
            to={`${ADMIN_PREFIX_PATH}/inbox/${mode}/${type}/${
             type === 'scheduled' ? item?.id : item?.lead_id
            }`}
           >
            <InboxItem
             item={item}
             refetchList={refetch}
             refetchListKey={`inbox-${
              mode === 'sms' ? 'text' : mode
             }-${type}-${searchKey}`}
            />
           </NavLink>
          </li>
         ))}
        </InfinityPagination>
       )
      )}
      {/* {(flatData?.length ?? 0) > 0 && (
       <InfinityPagination
        hasMore={hasMore}
        loadMore={fetchMore}
        useWindow={false}
       >
        {flatData?.map?.((item, i) => (
         <li key={i}>
          <NavLink
           to={`${ADMIN_PREFIX_PATH}/inbox/${mode}/${type}/${
            type === 'scheduled' ? item?.id : item?.lead_id
           }`}
          >
           <InboxItem item={item} refetchList={refetch} />
          </NavLink>
         </li>
        ))}
       </InfinityPagination>
      )} */}
     </ul>
    </Col>
    <Col className="inbox-content-area">
     {(flatData?.length ?? 0) > 0 && (
      <Routes>
       <Route
        path=""
        element={
         <div className="h-100 d-flex align-items-center justify-content-center">
          <Result
           subTitle={`Please select any ${
            params?.mode === 'sms' ? 'SMS' : 'Email'
           } box`}
           title="Lets Start !"
           status="info"
          />
         </div>
        }
       />
       <Route
        path=":id"
        element={
         type === 'scheduled' ? <InboxScheduleMsg /> : <InboxMessages />
        }
       />
      </Routes>
     )}
    </Col>
   </Row>
  </div>
 )
}

export default InboxType

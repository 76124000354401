/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */

import { Form, Input, UploadFile } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import {
 TSendMailBody,
 useSendEmailLeadMutation,
 useSendScheduleEmailLeadMutation,
 useSendScheduleEmailProMutation,
} from 'features/leads'
import { useSendListingEmailMutation } from 'api/baseApi'
import { useGetEmailTemplateByIdQuery } from 'features/settings'
import useAppControl from 'hooks/useAppControl'
import useAuth from 'hooks/useAuth'
import { useGlobal } from 'raducer/global'
import { FC, useEffect, useMemo, useState } from 'react'
import {
 convert2FormDataEmail,
 convetUrl2FileList,
} from 'components/shared/SendEmailForm/functions'
import EmailSubjectInput from 'components/shared/Common/EmailSubjectInput'
import SWToBarTag from 'components/elements/SWToBarTag/SWToBarTag'
import SWToBar from 'components/elements/SWToBar/SWToBar'
import { globalToaster } from 'components/elements/global'
import { inboxApi, useUpdateEmailScheduleMutation } from 'features/inbox'
import { queryClient } from 'lib/react-query'
import { useDispatch } from 'react-redux'
import { SendWidgetProps } from '../index'
import SendWidgetLayout from '../Layout'
import EmailTextEditor from '../_components/EMailTextEditor'
import { setProPreLoadContent } from '../functions'

type EmailSendWidgetProps = SendWidgetProps
export type TProLeadsSelectAll = {
 selectAll: boolean
 unSelected: any[]
 filterInfo: string
 showMoreLabel: boolean
 show: boolean
 selectedCount: number
 searchKey: string
}

const EmailSendWidget: FC<EmailSendWidgetProps> = ({
 onSendSuccess,
 mode,

 type,
 wFrom,
}) => {
 const { user } = useAuth()

 const initialValues: TSendMailBody = {
  to: [],
  from: user?.twilio_email,
  body: '',
 }
 const d = useDispatch()

 const { global } = useGlobal()
 const { sendWidget, updateSendEmailWidget, onSuccessEmail } = useAppControl()

 const {
  widgetFrom,
  selectAll,
  filterInfo,
  unSelected,
  to,
  propFullInfo,
  showMoreLabel,
  extraBody,
  propertyData,
  proListingType,
  disableBody,
  customtSelectAll,
  replyData,
  forwardData,
  editData,
  hideAttachment = false,
  inboxRefetchKey,
 } = sendWidget || {}

 const proLeadsSelectAllInitialValues = {
  selectAll: false,
  unSelected: [],
  filterInfo: '',
  showMoreLabel: false,
  show: widgetFrom === 'listing',
  selectedCount: 1,
  searchKey: '',
 }
 const [proLeadsSelectAll, setProLeadsSelectAll] = useState<
  TProLeadsSelectAll | undefined
 >(proLeadsSelectAllInitialValues)

 const [attachmentFile, setAttachmentFile] = useState<UploadFile[]>([])

 const [templateId, setTemplateId] = useState<any>()

 const [scheduleEmail, { isLoading: sendScheduleEmailLoading }] =
  useSendScheduleEmailLeadMutation()
 const [SendScheduleEmailPro, { isLoading: SendScheduleEmailProLoading }] =
  useSendScheduleEmailProMutation()
 const [sendEmail, { isLoading: sendEmailLoading }] = useSendEmailLeadMutation()
 const [sendlistingEmail, { isLoading: ListEmailLoading }] =
  useSendListingEmailMutation()
 const [updateEmailSchedule, { isLoading: emailScheduleLoading }] =
  useUpdateEmailScheduleMutation()

 const {
  data: templateData,
  isLoading,
  isFetching,
 } = useGetEmailTemplateByIdQuery({ id: templateId }, { skip: !templateId })

 const [sendMailForm] = Form.useForm<TSendMailBody>()
 const watch = Form.useWatch([], sendMailForm)

 const attachmentCount = useMemo(
  () => attachmentFile.length,
  [attachmentFile.length],
 )
 const productCount = useMemo(
  () => propFullInfo?.length || 0,
  [propFullInfo?.length],
 )

 const valueToField = useMemo(() => {
  if (type === 'tag') {
   return (watch?.to as any)?.map?.((i: any) => i?.id)?.join(',')
  }
  if (Array.isArray(watch?.to)) {
   return watch?.to?.join(',')
  }
  return watch?.to
 }, [widgetFrom, mode, watch])

 const isSelectAll = useMemo(() => {
  if (customtSelectAll !== undefined) {
   return customtSelectAll !== null
  }
  return selectAll ?? false
 }, [customtSelectAll, selectAll])

 const handleClickSend = async (time?: any, date?: any) => {
  const { to: toF, ...values } = await sendMailForm.validateFields()

  if (
   (isSelectAll !== true && toF?.length === 0) ||
   (proLeadsSelectAll?.selectAll && toF?.length === 0)
  ) {
   globalToaster({
    type: 'error',
    content: 'Add at least one recipient',
   })
  } else {
   if (mode === 'EDIT-SHEDULE-MAIL') {
    const formData = new FormData()

    const removedTo = (editData?.to as any)?.filter(
     (i: any) => !toF?.includes(i),
    )

    const newTo = (toF as any)?.filter(
     (item: any) => !editData?.to?.includes(item),
    )

    const fileAttachment = attachmentFile
     ?.filter((i) => editData?.attachment?.includes(i?.url))
     ?.map((i) => i?.url)

    const attachments = attachmentFile?.filter?.(
     (i) => !editData?.attachment?.includes(i?.url),
    )

    if ((removedTo?.length ?? 0) > 0) {
     formData.append('removed_to', removedTo)
    }

    if ((newTo?.length ?? 0) > 0) {
     formData.append('new_to', newTo)
    }

    formData.append('date', date)
    formData.append('time', time)
    formData.append('body', values?.body ?? '')
    formData.append('subject', values?.subject ?? '')
    if (templateData?.id) {
     formData.append('temp_id', templateData?.id)
    }
    if (fileAttachment?.length > 0) {
     fileAttachment?.forEach((i, n) => {
      formData.append(`file_attachment[${n}]`, i ?? '')
     })
    }

    if (attachments?.length > 0) {
     attachments?.forEach((i, n) => {
      formData.append(`attachment[${n}]`, i?.originFileObj as any)
     })
    }
    await updateEmailSchedule({ id: editData?.id, data: formData }).unwrap()
    // queryClient.invalidateQueries('inbox-scheduled-email')
    d(inboxApi.util.invalidateTags(['SCHEDULE-EMAIL-PREVIEW']))
    if (inboxRefetchKey) {
     queryClient.invalidateQueries(inboxRefetchKey)
    }
    onSuccessEmail?.()
   } else {
    const formData = convert2FormDataEmail({
     ...values,
     ...((!isSelectAll && widgetFrom !== 'listing') ||
     (widgetFrom === 'listing' && !proLeadsSelectAll?.selectAll) ||
     (selectAll && !showMoreLabel) ||
     (isSelectAll && !showMoreLabel) ||
     (proLeadsSelectAll?.selectAll && !proLeadsSelectAll?.showMoreLabel)
      ? {
         to: valueToField,
        }
      : {}),
     attachment: attachmentFile,
     ...(propFullInfo && propFullInfo.length > 0
      ? {
         property: propFullInfo?.map((i) => ({
          id: i?.slug,
          type: i?.listing_type,
         })),
         listing_type: proListingType,
        }
      : {}),
     ...(time || date ? { time, date } : {}),
     type,
     ...(propFullInfo ? { prop_full_info: propFullInfo } : {}),
     temp_id: templateData?.id,
     ...(selectAll && showMoreLabel ? { select_all: selectAll } : {}),
     ...(proLeadsSelectAll?.selectAll && proLeadsSelectAll?.showMoreLabel
      ? { select_all: proLeadsSelectAll?.selectAll }
      : {}),
     ...(customtSelectAll && showMoreLabel ? { customtSelectAll } : {}),
     ...(unSelected && isSelectAll && showMoreLabel
      ? { unselected: unSelected }
      : {}),
     ...(proLeadsSelectAll?.unSelected &&
     proLeadsSelectAll?.selectAll &&
     proLeadsSelectAll?.showMoreLabel
      ? { unselected: proLeadsSelectAll?.unSelected }
      : {}),
     ...(filterInfo && isSelectAll && showMoreLabel
      ? { filter_info: filterInfo }
      : {}),
     ...(proLeadsSelectAll?.filterInfo &&
     proLeadsSelectAll?.selectAll &&
     proLeadsSelectAll?.showMoreLabel
      ? { filter_info: proLeadsSelectAll?.filterInfo }
      : {}),
     ...extraBody,
    })

    if (propFullInfo && propFullInfo?.length > 0) {
     time || date
      ? await SendScheduleEmailPro(formData).unwrap()
      : await sendlistingEmail(formData).unwrap()
    } else {
     time || date
      ? await scheduleEmail(formData).unwrap()
      : await sendEmail(formData).unwrap()
    }
   }
   onSendSuccess?.()
   onSuccessEmail?.()
  }
 }

 useEffect(() => {
  if (widgetFrom === 'listing' && (propFullInfo?.length ?? 0) > 0) {
   if (
    !(proListingType === 'preconstruction' && (propFullInfo?.length ?? 0) < 2)
   ) {
    sendMailForm.setFieldsValue({
     body: setProPreLoadContent({
      conte: global?.listing_mail_content,
      single: propFullInfo?.length === 1,
      type: proListingType,
      showPreConSingle: false,
     }),
    })
   }
   sendMailForm.setFieldsValue({
    subject: setProPreLoadContent({
     conte: global?.listing_mail_subject,
     single: propFullInfo?.length === 1,
     type: proListingType,
    }),
   })
  }
 }, [sendWidget?.propFullInfo])

 useEffect(() => {
  if (templateData) {
   sendMailForm.setFieldsValue({
    subject: templateData.subject ?? '',
    body: `${templateData.body ?? ''}`,
    attachment: convetUrl2FileList(templateData.attachment),
   })
  }
 }, [templateData])

 useEffect(() => {
  if (
   (!propFullInfo || (propFullInfo && propFullInfo.length === 0)) &&
   user?.signature &&
   editData === undefined
  ) {
   sendMailForm.setFieldsValue({
    body: user.signature,
   })
  }
 }, [user?.signature])

 useEffect(() => {
  if (to && mode !== 'FORWARD') {
   sendMailForm.setFieldsValue({
    to,
   })
  }
 }, [to])

 useEffect(() => {
  if (replyData && mode === 'REPLY') {
   sendMailForm.setFieldsValue({
    subject: `${replyData.subject}`,
    attachment: convetUrl2FileList(replyData?.attachment),
    to: replyData?.to,
   })
   setAttachmentFile(convetUrl2FileList(replyData?.attachment))
  } else if (mode === 'FORWARD' && forwardData) {
   sendMailForm.setFieldsValue({
    subject: `${forwardData.subject}`,
    attachment: convetUrl2FileList(forwardData.attachment),
   })
   setAttachmentFile(convetUrl2FileList(forwardData.attachment))
  } else if (mode === 'EDIT-SHEDULE-MAIL' && editData) {
   sendMailForm.setFieldsValue({
    subject: `${editData.subject}`,
    body: `${editData?.body}`,
    attachment: convetUrl2FileList(editData?.attachment),
   })
   setAttachmentFile(convetUrl2FileList(editData?.attachment))
  }
  return () => {
   sendMailForm.resetFields()
  }
 }, [mode, forwardData, replyData, editData])

 const toFieldValue = useMemo(() => {
  if (type === 'lead') {
   return Array.isArray(watch?.to)
    ? { to: watch?.to?.join(',') ?? [] }
    : { to: watch?.to }
  }
  return {
   to: (watch?.to as [])?.map((i: any) => i?.id)?.join(',') ?? [],
  }
 }, [type, watch])

 return (
  <SendWidgetLayout
   type={sendWidget?.type}
   proData={sendWidget?.propFullInfo}
   onUpdateProData={(val: any) => {
    updateSendEmailWidget({ propFullInfo: val })
   }}
   loading={isLoading || isFetching}
   onSchedule={(time, date) => handleClickSend(time, date)}
   scheduleLoading={
    SendScheduleEmailProLoading ||
    sendScheduleEmailLoading ||
    emailScheduleLoading
   }
   submitLoading={
    ListEmailLoading ||
    sendEmailLoading ||
    emailScheduleLoading ||
    SendScheduleEmailProLoading ||
    sendScheduleEmailLoading
   }
   onSend={() => handleClickSend()}
   productCount={productCount}
   attachment={{
    count: attachmentCount,
    onChange: (v) => setAttachmentFile(v),
    value: attachmentFile,
   }}
   previewData={{
    body: watch?.body,
    subject: watch?.subject,
    from: user?.twilio_email,
    type,
    ...(propFullInfo && widgetFrom === 'listing'
     ? { prop_full_info: propFullInfo }
     : {}),
    ...(propFullInfo && propFullInfo?.length > 0 && widgetFrom === 'listing'
     ? {
        listing_type: proListingType,
        property: JSON.stringify(
         propFullInfo?.map((i) => ({
          id: i?.slug,
          type: i?.listing_type,
         })),
        ),
       }
     : {}),
    ...toFieldValue,
   }}
   widgetFrom={widgetFrom}
   from="Email"
   handleValidation={() => {
    if (
     !toFieldValue?.to ||
     (toFieldValue?.to && toFieldValue?.to.length === 0)
    ) {
     globalToaster({ type: 'error', content: 'Enter To Input' })
     return false
    }
    if (!watch?.subject) {
     globalToaster({ type: 'error', content: 'Enter Subject' })
     return false
    }
    return true
   }}
   hideAttachment={hideAttachment}
   mode={mode}
   editData={editData}
  >
   <div className="sw-form-section">
    <Form initialValues={initialValues} form={sendMailForm}>
     <Form.Item name="from" label="From">
      <Input readOnly />
     </Form.Item>

     <FormItem label="To" name="to">
      {type === 'tag' ? (
       <SWToBarTag type="email" />
      ) : (
       <SWToBar
        type="email"
        proLeadsSelectAll={proLeadsSelectAll}
        setProLeadsSelectAll={setProLeadsSelectAll}
       />
      )}
     </FormItem>

     <FormItem name="subject">
      <EmailSubjectInput
       mergeField={mode !== 'REPLY'}
       readOnly={mode === 'REPLY'}
      />
     </FormItem>
     {proListingType === 'preconstruction' && propFullInfo?.length === 1 ? (
      <div className="d-flex align-items-center justify-content-center w-100 mt-5">
       <p style={{ fontSize: '15px', fontWeight: '500' }}>
        Note: The Pre-con single listing emails will be shared as a pre-designed
        template. The compose option will not be available for such emails.
        Please use the preview option to view the template
       </p>
      </div>
     ) : (
      <FormItem name="body" className="sw-texteditor">
       <EmailTextEditor
        emailTemplate={widgetFrom !== 'listing'}
        onSelectTemplate={(id) => setTemplateId(id)}
       />
      </FormItem>
     )}
    </Form>
   </div>
  </SendWidgetLayout>
 )
}

export default EmailSendWidget

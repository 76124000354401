/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import { Header } from '@tanstack/react-table'
import { Popover, Button, Badge } from 'antd'
import { FC, useState } from 'react'
import { MTFilterProps } from '../type'
import MTFilterBlock from './MTFilterBlock'

const MTFilterPanel: FC<{
 header: Header<any, any>
 filter?: MTFilterProps
}> = ({ filter, header }) => {
 const [showF, setShowF] = useState(false)
 return (
  <Popover
   destroyTooltipOnHide
   overlayClassName="filter-popover"
   //  open={showF}
   //  onOpenChange={(v) => setShowF(v)}
   onVisibleChange={(v) => setShowF(v)}
   visible={showF}
   placement="bottom"
   content={
    <MTFilterBlock
     showSelectAll={filter?.showSelectAll}
     searchPlaceholder={filter?.searchPlaceholder}
     showSearch={filter?.showSearch}
     selectedValue={header.column.getFilterValue()}
     customNode={filter?.customNode}
     onClickSubmit={(val) => {
      const newVal =
       filter?.mode === 'single'
        ? val?.[0] || null
        : val && val?.length > 0
        ? val
        : null
      header.column.setFilterValue(newVal)
     }}
     mode={filter?.mode}
     options={filter?.options}
     onHide={() => setShowF(false)}
    />
   }
   trigger="click"
  >
   <Button className="mt-filter-btn" type="link">
    <Badge
     dot={
      filter?.showFilteCustomDot
       ? header.column.getIsFiltered() &&
         !filter?.filterDotExcludeValues?.includes(
          header.column.getFilterValue() as string,
         )
       : header.column.getIsFiltered()
     }
    >
     <i className="fal fa-filter" />
    </Badge>
   </Button>
  </Popover>
 )
}

export default MTFilterPanel

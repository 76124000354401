import { Col, Form, Row, Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { BaseEditor } from 'components/elements/BaseEditor'
import { useEditSignatureMutation } from 'features/settings/api'
import useAuth from 'hooks/useAuth'
import { FC, useEffect, useState } from 'react'

const Add: FC<{ fillData?: string }> = ({ fillData }) => {
 const [form] = Form.useForm<{ signature?: any }>()
 const { changeSignature } = useAuth()

 useEffect(() => {
  if (fillData) {
   form.setFieldsValue({
    signature: fillData,
   })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [fillData])

 const [editSignature, { isLoading }] = useEditSignatureMutation()

 const HandleSubmit = async () => {
  const values = await form.validateFields()
  await editSignature({ ...values }).unwrap()
  changeSignature(values?.signature)
 }
 const [loadingEditor, setLoadingEditor] = useState(true)

 return (
  <div className="signature-section">
   <Form layout="vertical" form={form}>
    <Row gutter={15}>
     <Col span={24}>
      <Form.Item name="signature">
       <BaseEditor
        height={450}
        loading={loadingEditor}
        setLoading={setLoadingEditor}
       />
      </Form.Item>
     </Col>
    </Row>

    {!loadingEditor && (
     <Row>
      <Col span={24}>
       <p className="text-center">Note: You can set your signature here!!!</p>
      </Col>
      <Col span={12}>
       <Space>
        <BaseButton
         buttonType="primary"
         onClick={HandleSubmit}
         loading={isLoading}
        >
         Update Signature
        </BaseButton>
       </Space>
      </Col>
     </Row>
    )}
   </Form>
  </div>
 )
}

export default Add

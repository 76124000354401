import { Form, Input } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { BaseButton } from 'components/elements/BaseButton'
import BackgroundModal, {
 TAddBackGroundType,
} from 'components/shared/Modals/BackgroundModal'
import TextareaAutosize from 'react-textarea-autosize'

import React, { FC, useEffect, useRef, useState } from 'react'
import ProInnerCard from '../../ProInnerCard'

const BackgroundBlock: FC<{ background?: any; isTransferred?: boolean }> = ({
 background,
 isTransferred,
}) => {
 const [showModal, setShowModal] = useState(false)

 const formInit: TAddBackGroundType = {
  background: '',
  lead_id: null,
 }
 const [form] = useForm<TAddBackGroundType>()

 useEffect(() => {
  if (background) {
   form.setFieldsValue({
    background,
   })
  }
 }, [background])

 return (
  <div>
   <ProInnerCard
    title="Background"
    onCLickAdd={
     background || !isTransferred ? undefined : () => setShowModal(true)
    }
   >
    <Form form={form} initialValues={formInit} className="background-form">
     <Form.Item name="background">
      <TextareaAutosize
       minRows={3}
       maxRows={3}
       className="background-textarea w-100 auto-size-text-area"
       readOnly
       style={{ paddingRight: '45px' }}
      />
     </Form.Item>
     {background && isTransferred && (
      <BaseButton
       size="small"
       buttonStyle="outline"
       buttonType="primary"
       icon={<i className="fas fa-pencil-alt" />}
       className="background-button"
       onClick={() => setShowModal(true)}
      />
     )}
    </Form>
   </ProInnerCard>
   <BackgroundModal
    visible={showModal}
    onCancel={() => setShowModal(false)}
    background={background}
   />
  </div>
 )
}

export default BackgroundBlock

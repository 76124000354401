/* eslint-disable jsx-a11y/control-has-associated-label */
import { Table } from '@tanstack/react-table'
import { FC } from 'react'
import MTColGroup from '../MTColGroup'
import MTHeaderCell from './MTHeaderCell'

const MTHeader: FC<{
 table: Table<any>
}> = ({ table }) => {
 const lastColumn: any = table.getAllColumns()[table.getAllColumns().length - 1]
 return (
  <table>
   <MTColGroup type="header" />
   <thead className="m-table-thead">
    {table.getHeaderGroups().map((headerGroup) => (
     <tr key={headerGroup.id}>
      {headerGroup.headers.map((header: any) => {
       return (
        <MTHeaderCell
         isScroll={lastColumn.columnDef.meta?.fixed === 'right'}
         filter={header.column.columnDef.meta?.filter}
         key={header.id}
         header={header}
        />
       )
      })}
      <th className="m-table-cell" style={{ position: 'sticky', right: 0 }} />
     </tr>
    ))}
   </thead>
  </table>
 )
}

export default MTHeader

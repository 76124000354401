import InnerContentLayout from 'layout/InnerContentLayout'
import { FC } from 'react'
import useAuth from 'hooks/useAuth'
import Add from './components/Add'

const Signature: FC = () => {
 const { user } = useAuth()

 return (
  <InnerContentLayout title="Signature">
   <Add fillData={user?.signature ?? ''} />
  </InnerContentLayout>
 )
}

export default Signature

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import { Form, Spin, Row, Col, Card, List, Badge, Input } from 'antd'
import { useSendListingEmailMutation } from 'api/baseApi'
import { BaseButton } from 'components/elements/BaseButton'
import { BtnAnch } from 'components/elements/global'
import {
 TForwardData,
 TReplyData,
 TSendMailBody,
 TSendMailPro,
 TSendType,
 useSendEmailLeadMutation,
 useSendScheduleEmailLeadMutation,
 useSendScheduleEmailProMutation,
} from 'features/leads'
import { TPropertyListing } from 'features/property'
import { useGetEmailTemplateByIdQuery } from 'features/settings'
import useAuth from 'hooks/useAuth'
import { FC, useState, useEffect, useMemo } from 'react'
import { FCC, NewListingSlug, TMsgMode } from 'types'
import { imagePrefix } from 'utils/functions'
import SWToBar from 'components/elements/SWToBar/SWToBar'
import SWToBarTag from 'components/elements/SWToBarTag/SWToBarTag'
import EmailATUpload from '../Common/EmailATUpload'
import EmailSubjectInput from '../Common/EmailSubjectInput'
import EmailTextArea from '../Common/EmailTextArea'
import ScheduleModal from '../Modals/ScheduleModal'
import { ModalTypes } from '../Modals/SendEmailModal'
import PropertyCard from '../PropertyCard'
import EmailPreview from './EmailPreview'
import { convert2FormDataEmail, convetUrl2FileList } from './functions'

type SendEmailProp = {
 replyData?: TReplyData
 sendType?: TSendType
 onSendSuccess?: () => void
 type?: 'page' | 'inner'
 to?: string
 body?: string
 disableBody?: boolean
 subject?: any
 mode?: TMsgMode
 propertyData?: TSendMailPro[]
 forwardData?: TForwardData
 listingType?: NewListingSlug
 allData?: TPropertyListing[]
 isAttachment?: boolean
 modalType?: ModalTypes
 propFullInfo?: TPropertyListing[]
 updateSelectedProp?: (val?: any) => void
 removeTemplateDropdown?: boolean
 selectAll?: boolean
 unSelected?: number[]
 filterInfo?: string
 totalCount?: number
 showMoreLabel?: boolean
 extraBody?: any
 rmMergeFields?: string[]
}

const initialValues: TSendMailBody = {
 to: [],
}

const SendEmail: FCC<SendEmailProp> = ({
 sendType = 'lead',
 type = 'page',
 to,
 body,
 disableBody = false,
 mode = 'SEND',
 subject,
 onSendSuccess,
 propertyData,
 forwardData,
 replyData,
 listingType,
 allData,
 isAttachment,
 modalType,
 propFullInfo,
 updateSelectedProp,
 removeTemplateDropdown,
 selectAll,
 unSelected,
 filterInfo,
 totalCount,
 showMoreLabel,
 extraBody = {},
 rmMergeFields,
}) => {
 const { user } = useAuth()

 const [scheduleEmail, { isLoading: sendScheduleEmailLoading }] =
  useSendScheduleEmailLeadMutation()
 const [SendScheduleEmailPro, { isLoading: SendScheduleEmailProLoading }] =
  useSendScheduleEmailProMutation()
 const [sendEmail, { isLoading: sendEmailLoading }] = useSendEmailLeadMutation()
 const [sendlistingEmail, { isLoading: ListEmailLoading }] =
  useSendListingEmailMutation()
 //  const [tempAttachment, setTempAtachment] = useState<string[]>([])
 const [templateId, setTemplateId] = useState<any>()
 const {
  data: templateData,
  isLoading,
  isFetching,
 } = useGetEmailTemplateByIdQuery({ id: templateId }, { skip: !templateId })
 const [sendMailForm] = Form.useForm<TSendMailBody>()
 const toWatch = Form.useWatch('to', sendMailForm)
 const subjectField = Form.useWatch('subject', sendMailForm)
 const [showPreview, setShowPreview] = useState<boolean>(false)
 const [showScheduleEmail, setShowScheduleEmail] = useState<boolean>(false)
 const [dataState, setDataState] = useState<TPropertyListing[] | undefined>(
  allData,
 )
 const [previewBtnDisableOnTag, setPreviewBtnDisableOntag] = useState(false)
 //  console.log(toWatch, 'toWatch')
 const updatedState = useMemo(
  () =>
   dataState && dataState.length > 0
    ? dataState.map((v) => ({
       id: v.slug,
       type: v.listing_type,
      }))
    : [],
  [dataState],
 )

 const handleSendEmail = async (time?: any, date?: any): Promise<void> => {
  const values = await sendMailForm.validateFields()
  const formData: FormData = convert2FormDataEmail({
   ...values,
   //  temp_attachment: tempAttachment,
   from: user?.twilio_email,
   ...(!selectAll
    ? {
       to:
        mode === 'REPLY'
         ? sendType === 'lead'
           ? Array.isArray(values.to)
             ? values.to.join(',')
             : values?.to
           : to
         : sendType === 'lead'
         ? Array.isArray(values.to)
           ? (values?.to as any)?.join(',')
           : values?.to
         : (values?.to as any)?.map((i: any) => i?.id)?.join(','),
      }
    : {}),

   ...(propertyData && propertyData.length > 0
    ? {
       property: updatedState,
       listing_type: listingType,
      }
    : {}),
   ...(time || date ? { time, date } : {}),
   type: sendType,
   ...(propFullInfo ? { prop_full_info: propFullInfo } : {}),
   temp_id: templateData?.id,
   ...(selectAll && showMoreLabel ? { select_all: selectAll } : {}),
   ...(unSelected && selectAll && showMoreLabel
    ? { unselected: unSelected }
    : {}),
   ...(filterInfo && selectAll && showMoreLabel
    ? { filter_info: filterInfo }
    : {}),
   ...extraBody,
  })
  if (propertyData && propertyData.length > 0) {
   time || date
    ? await SendScheduleEmailPro(formData).unwrap()
    : await sendlistingEmail(formData).unwrap()
   onSendSuccess?.()
  } else {
   time || date
    ? await scheduleEmail(formData).unwrap()
    : await sendEmail(formData).unwrap()
   onSendSuccess?.()
  }
 }

 useEffect(() => {
  if (templateData) {
   sendMailForm.setFieldsValue({
    subject: templateData.subject ?? '',
    body: `${templateData.body ?? ''}`,
    attachment: convetUrl2FileList(templateData.attachment),
   })
  }
 }, [templateData])

 useEffect(() => {
  if (
   (!propertyData || (propertyData && propertyData.length === 0)) &&
   user?.signature
  ) {
   sendMailForm.setFieldsValue({
    body: user.signature,
   })
  }
 }, [user?.signature])

 useEffect(() => {
  if (to && mode !== 'FORWARD') {
   sendMailForm.setFieldsValue({
    to,
   })
  }
 }, [to])

 useEffect(() => {
  if (body) {
   sendMailForm.setFieldsValue({
    body,
   })
  }
 }, [body])

 useEffect(() => {
  if (replyData && mode === 'REPLY') {
   sendMailForm.setFieldsValue({
    subject: `${replyData.subject}`,
    to: replyData?.to,
   })
  } else if (mode === 'FORWARD' && forwardData) {
   sendMailForm.setFieldsValue({
    subject: `${forwardData.subject}`,
    attachment: convetUrl2FileList(forwardData.attachment),
   })
  }
  return () => {
   sendMailForm.resetFields()
  }
 }, [mode, subject, forwardData])

 const normFile = (e: any) => {
  if (Array.isArray(e)) {
   return e
  }
  return e && e.fileList
 }

 const onClickButton = async (type: 'preview' | 'schedule') => {
  await sendMailForm.validateFields()
  if (type === 'preview') {
   setShowPreview(true)
  }
  if (type === 'schedule') {
   setShowScheduleEmail(true)
  }
 }

 return (
  <div className={type === 'page' ? 'email-middle' : 'email-small'}>
   <Spin spinning={isFetching || isLoading}>
    <Form initialValues={initialValues} form={sendMailForm} layout="vertical">
     {modalType === 'listing' ? (
      <Row gutter={12}>
       <Col span={16}>
        <Row>
         <Col span={24} className="sw-to-bar-custom-input">
          <Form.Item
           rules={[
            {
             required: !selectAll,
             message: `${sendType === 'lead' ? 'Email' : 'Tag'} is required`,
            },
           ]}
           name="to"
           label="To"
           getValueFromEvent={(v) => {
            if (sendType !== 'lead') {
             setPreviewBtnDisableOntag(v?.length > 0)
            }
            return v
           }}
          >
           {sendType === 'lead' ? (
            <SWToBar
             //  selectAll={selectAll}
             showAdd
             type="email"
             //  selectedToCount={`${
             //   (totalCount ?? 0) - (unSelected?.length || 0)
             //  }`}
            />
           ) : (
            <SWToBarTag type="email" />
           )}
          </Form.Item>
         </Col>
         <Col span={24}>
          <Form.Item
           name="subject"
           label="Subject"
           rules={[{ required: true, message: 'Subject is Required' }]}
          >
           <EmailSubjectInput
            mergeField={mode !== 'REPLY'}
            readOnly={mode === 'REPLY'}
           />
          </Form.Item>
         </Col>
         {!disableBody && (
          <Col span={24}>
           <Form.Item name="body">
            <EmailTextArea
             height={260}
             onSelectTemplate={(id) => setTemplateId(id)}
             mergfield
             emailTemplate={!removeTemplateDropdown}
             disabled={disableBody}
             rmMergeFields={rmMergeFields}
            />
           </Form.Item>
          </Col>
         )}
         {disableBody && (
          <div
           className="d-flex align-items-center justify-content-center w-100"
           style={{ height: '200px' }}
          >
           <p style={{ fontSize: '15px', fontWeight: '500' }}>
            The Pre-con single listing emails will be shared as a pre-designed
            template. The compose option will not be available for such emails.
            Please use the preview option to view the template
           </p>
          </div>
         )}
         {!isAttachment && (
          <Col span={24}>
           <Form.Item
            name="attachment"
            //   valuePropName="fileList"
            getValueFromEvent={normFile}
           >
            <EmailATUpload />
           </Form.Item>
          </Col>
         )}
        </Row>
       </Col>
       <Col span={8}>
        <Row>
         {dataState && dataState.length > 0 && (
          <Col span={24}>
           <Card
            className="mb-1"
            type="inner"
            title={
             <>
              Properties <Badge count={dataState.length} />
             </>
            }
           >
            <div className="listing-modal-rightside-email">
             <Row gutter={[10, 10]}>
              {dataState.map((item, i) => (
               <Col key={i} span={24}>
                <PropertyCard
                 item={item}
                 cardType="inside-mail-modal"
                 dataState={dataState}
                 handleDataState={(val) => {
                  if (val?.length === 1) {
                   sendMailForm.setFieldsValue({
                    body: '',
                   })
                  }
                  setDataState?.(val)
                  updateSelectedProp?.(val)
                 }}
                />
               </Col>
              ))}
             </Row>
            </div>
           </Card>
          </Col>
         )}
        </Row>
       </Col>

       <Col className="d-flex justify-content-end" span={24}>
        <BaseButton
         disabled={
          // sendType === 'lead' ? !toWatch?.length : !previewBtnDisableOnTag
          subjectField === undefined ||
          subjectField === '' ||
          ListEmailLoading ||
          (showMoreLabel
           ? false
           : sendType === 'lead'
           ? !toWatch?.length
           : !previewBtnDisableOnTag)
          //  ? true
          //  : !selectAll
          //  ? false
          //  : sendType === 'lead'
          //  ? !toWatch?.length
          //  : !previewBtnDisableOnTag
         }
         size={type === 'page' ? 'middle' : 'small'}
         onClick={() => onClickButton('preview')}
         buttonType="primary"
         tooltipText={
          subjectField === undefined ||
          subjectField === '' ||
          (sendType === 'lead' ? !toWatch?.length : !previewBtnDisableOnTag)
           ? 'Fill in the mandatory fields above to enable the Preview Option'
           : undefined
         }
        >
         <i className="fas fa-eye me-1" /> Preview
        </BaseButton>
        <BaseButton
         loading={sendEmailLoading || ListEmailLoading}
         onClick={() => handleSendEmail()}
         size={type === 'page' ? 'middle' : 'small'}
         customClass="mx-2"
         buttonType="success"
        >
         <i className="fas fa-paper-plane me-1" /> Send
        </BaseButton>
        <BaseButton
         disabled={sendEmailLoading || ListEmailLoading}
         size={type === 'page' ? 'middle' : 'small'}
         onClick={() => onClickButton('schedule')}
         buttonType="info"
        >
         <i className="fas fa-clock me-1" /> Schedule
        </BaseButton>
       </Col>
      </Row>
     ) : (
      <Row gutter={16}>
       <Col span={24}>
        <Form.Item label="From">
         <Input readOnly value={user?.twilio_email} />
        </Form.Item>
       </Col>
       <Col span={24} className="sw-to-bar-custom-input">
        <Form.Item
         rules={[
          {
           required: !selectAll,
           message: `${sendType === 'lead' ? 'Email' : 'Tag'} is required`,
          },
         ]}
         name="to"
         label="To"
         getValueFromEvent={(v) => {
          if (sendType !== 'lead') {
           setPreviewBtnDisableOntag(v?.length > 0)
          }
          return v
         }}
        >
         {sendType === 'lead' ? (
          <SWToBar
           readonly={replyData && mode === 'REPLY'}
           showAdd
           type="email"
           //  showMoreLabel={showMoreLabel}
          />
         ) : (
          <SWToBarTag type="email" />
         )}
        </Form.Item>
       </Col>
       <Col span={24}>
        <Form.Item
         name="subject"
         label="Subject"
         rules={[{ required: true, message: 'Subject is Required' }]}
        >
         <EmailSubjectInput
          mergeField={mode !== 'REPLY'}
          readOnly={mode === 'REPLY'}
         />
        </Form.Item>
       </Col>
       <Col span={24}>
        <Form.Item name="body">
         <EmailTextArea
          height={260}
          onSelectTemplate={(id) => setTemplateId(id)}
          mergfield
          emailTemplate={!removeTemplateDropdown}
          disabled={disableBody}
         />
        </Form.Item>
       </Col>
       <Col span={24}>
        <Form.Item name="attachment" getValueFromEvent={normFile}>
         <EmailATUpload />
        </Form.Item>
       </Col>
       <Col className="d-flex justify-content-end" span={24}>
        <BaseButton
         //  disabled={!toWatch?.length}
         disabled={
          // subjectField === undefined || subjectField === '' || sendEmailLoading
          //  ? true
          //  : selectAll
          //  ? false
          //  : !toWatch?.length
          subjectField === undefined ||
          subjectField === '' ||
          ListEmailLoading ||
          (showMoreLabel ? false : !toWatch?.length)
         }
         size={type === 'page' ? 'middle' : 'small'}
         onClick={() => onClickButton('preview')}
         buttonType="primary"
         tooltipText={
          subjectField === undefined || subjectField === '' || !toWatch?.length
           ? 'Fill in the mandatory fields above to enable the Preview Option'
           : undefined
         }
        >
         <i className="fas fa-eye me-1" /> Preview
        </BaseButton>
        <BaseButton
         loading={sendEmailLoading || ListEmailLoading}
         onClick={() => handleSendEmail()}
         size={type === 'page' ? 'middle' : 'small'}
         customClass="mx-2"
         buttonType="success"
        >
         <i className="fas fa-paper-plane me-1" /> Send
        </BaseButton>
        <BaseButton
         disabled={sendEmailLoading || ListEmailLoading}
         size={type === 'page' ? 'middle' : 'small'}
         onClick={() => onClickButton('schedule')}
         buttonType="info"
        >
         <i className="fas fa-clock me-1" /> Schedule
        </BaseButton>
       </Col>
      </Row>
     )}
    </Form>
   </Spin>

   {showPreview && (
    <EmailPreview
     body={{
      body: sendMailForm.getFieldValue('body'),
      from: user?.twilio_email,
      subject: sendMailForm.getFieldValue('subject'),
      type: sendType,
      property:
       propertyData && propertyData.length > 0
        ? JSON.stringify(propertyData)
        : '',
      ...(propFullInfo ? { prop_full_info: propFullInfo } : {}),
      ...(propertyData && propertyData.length > 0
       ? { listing_type: listingType }
       : {}),
      // ...(!selectAll
      //  ?
      ...(sendType === 'lead'
       ? Array.isArray(sendMailForm.getFieldValue('to'))
         ? { to: sendMailForm.getFieldValue('to').join(',') }
         : { to }
       : {
          to: (sendMailForm.getFieldValue('to') as any)
           ?.map((i: any) => i?.id)
           ?.join(','),
         }),
      //  : {}),
      // ...(selectAll ? { select_all: selectAll } : {}),
      // ...(unSelected && selectAll ? { unselected: unSelected } : {}),
      // ...(filterInfo && selectAll ? { filter_info: filterInfo } : {}),
     }}
     onCancel={() => setShowPreview(false)}
     visible={showPreview}
    />
   )}
   <ScheduleModal
    isLoading={sendScheduleEmailLoading || SendScheduleEmailProLoading}
    onSubmitSchedule={handleSendEmail}
    type="EMAIL"
    onCancel={() => setShowScheduleEmail(false)}
    visible={showScheduleEmail}
   />
  </div>
 )
}

export default SendEmail

export const TenplateAttachment: FC<{
 tempAtach?: string[]
 onDelete?: (index?: any) => void
}> = ({ tempAtach, onDelete }) => {
 if (!tempAtach || (tempAtach && tempAtach.length === 0)) {
  return null
 }
 return (
  <Card>
   <h5>Template Attachments</h5>
   <List>
    <Row gutter={[10, 10]}>
     {tempAtach.map((v, i) => (
      <Col key={i} span={12}>
       <div className="ui-list-card">
        <div className="content">
         <a target="_blank" href={imagePrefix(v)?.url} rel="noreferrer">
          {' '}
          {imagePrefix(v)?.name}
         </a>
        </div>
        {onDelete && (
         <BtnAnch onClick={() => onDelete?.(v)} className="action">
          {' '}
          <i className="fas fa-trash text-danger" />{' '}
         </BtnAnch>
        )}
       </div>
      </Col>
     ))}
    </Row>
   </List>
  </Card>
 )
}

/* eslint-disable react/require-default-props */
import { Button, Tooltip } from 'antd'
import { TooltipPlacement } from 'antd/es/tooltip'
import { ButtonProps } from 'antd/lib/button/button'
import React, { FC } from 'react'
import { FCC } from 'types'

type NewBaseButtonProps = ButtonProps & {
 tooltipText?: string
 buttonType?:
  | 'primary'
  | 'success'
  | 'secondary'
  | 'info'
  | 'warning'
  | 'danger'
  | 'alpha'
  | 'beta'
  | 'gamma'
 buttonStyle?: 'solid' | 'outline'
 customClass?: string
 tooltipProps?: {
  placement?: TooltipPlacement
  getTooltipContainer?: (node: HTMLElement) => HTMLElement
 }
}

export const BaseButton: FCC<NewBaseButtonProps> = ({
 tooltipText,
 children,
 buttonType,
 buttonStyle,
 customClass,
 tooltipProps = {},
 ...rest
}) => (
 <Tooltip {...tooltipProps} title={tooltipText}>
  <Button
   className={`${
    buttonStyle === 'outline'
     ? `ant-btn-outline-${
        buttonType === 'danger' ? 'danger2' : buttonType ?? ''
       } `
     : `ant-btn-${buttonType === 'danger' ? 'danger2' : buttonType} `
   } ${customClass}`}
   {...rest}
  >
   {children}
  </Button>
 </Tooltip>
)
